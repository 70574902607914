import React, { useEffect, useState } from 'react'
import ProfileNavbar from '../profileNavbar/ProfileNavbar'
import { VideoBackground } from '../login/Login.styled'
import backgroundVideo from "../assets/backgroundClear.mp4"
import { ChangeButton, ChangeWrapper, ErrorSection, Input, InputName, InputWrapper } from './ChangeUserData.styled'
import api from '../helpers/api'
import { useTranslation } from 'react-i18next'

const ChangeUserData = () => {
  const [user, setUser] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userNameBusyError, setUserNameBusyError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailBusyError, setEmailBusyError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) {
      return;
    }
    (async () => {
      await api(
        "get",
        "user/data",
        {},

        {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      ).then(({ data }) => {
        localStorage.setItem("id", data.id);
        setUser(data);
        setFirstName(data?.firstName);
        setLastName(data?.lastName);
        setUserName(data?.username);
        setEmail(data?.email);
        setGender(data?.gender);
      });
    })();
  }, []);

  const submit = async () => {
    resetErrors();
    if (!firstName) {
      setFirstNameError(true);
    }
    if (!lastName) {
      setLastNameError(true);
    }
    if (!userName) {
      setUserNameError(true);
    }
    if (!email) {
      setEmailError(true);
    }
    if (!firstName || !lastName || !userName || !email) {
      return;
    }

    const data = {
      username: userName,
      firstName,
      lastName,
      gender,
      email,
    };

    try {
      await api(
        'post',
        'user/change',
        data,
        {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      )
      .then(({ data }) => {
        if(data?.token){
          localStorage.setItem("token", data.token);
        }
      })
    }
    catch (e) {
      if (e?.response?.data?.message === "username is busy") {
        setUserNameBusyError(true);
      }
      if(e?.response?.data?.message === 'email is busy') {
        setEmailBusyError(true);
      }
    }
  }
  const resetErrors = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setUserNameError(false);
    setUserNameBusyError(false);
    setEmailError(false);
    setEmailBusyError(false);
  }
  return (
    <>
      <ProfileNavbar />
      <VideoBackground>
        <video autoPlay loop muted playsInline>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <ChangeWrapper>
          <InputWrapper>
            <InputName>*{t('firstName')}</InputName>
            <div>
              <Input
                onInput={(e) => setFirstName(e.target.value)}
                defaultValue={firstName}
              />
              {firstNameError && (
                <ErrorSection>{t('firstNameRequired')}</ErrorSection>
              )}
            </div>
          </InputWrapper>
          <InputWrapper>
            <InputName>*{t('lastName')}</InputName>
            <div>
              <Input
                onInput={(e) => setLastName(e.target.value)}
                defaultValue={lastName}
              />
              {lastNameError && (
                <ErrorSection>{t('lastNameRequired')}</ErrorSection>
              )}
            </div>
          </InputWrapper>
          <InputWrapper>
            <InputName>*{t('userName')}</InputName>
            <div>
              <Input
                onInput={(e) => setUserName(e.target.value)}
                defaultValue={userName}
              />
              {userNameError && (
                <ErrorSection>{t('userNameRequired')}</ErrorSection>
              )}
              {userNameBusyError && (
                <ErrorSection>{t('userNameBusy')}</ErrorSection>
              )}
            </div>
          </InputWrapper>
          <InputWrapper>
            <InputName>*{t('email')}</InputName>
            <div>
              <Input
                onInput={(e) => setEmail(e.target.value)}
                defaultValue={email}
              />
              {emailError && (
                <ErrorSection>{t('emailRequired')}</ErrorSection>
              )}
              {emailBusyError && (
                <ErrorSection>{t('emailBusy')}</ErrorSection>
              )}
            </div>
          </InputWrapper>
          <InputWrapper>
            <InputName>*{t('gender')}</InputName>
            <input
              type="radio"
              id="male"
              value="male"
              checked={gender === "male"}
              onChange={(e) => {
                setGender(e.target.value);
              }}
            />
            <label htmlFor="male">{t('male')}</label>
            <input
              type="radio"
              id="female"
              value="female"
              checked={gender === "female"}
              onChange={(e) => {
                setGender(e.target.value);
              }}
            />
            <label htmlFor="female">{t('female')}</label>
          </InputWrapper>
          <ChangeButton onClick={submit}>{t('changeInformation')}</ChangeButton>
        </ChangeWrapper>
      </VideoBackground>
    </>
  )
}

export default ChangeUserData

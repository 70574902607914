import React from "react";
import imageFemale from "../assets/female.jpg";
import imageMale from "../assets/male.jpg";
import { ElementWrapper, ProfileImage, UserNameWrapper } from "./Network.style";
import MaleSVG from "../assets/male.svg";
import FemaleSVG from "../assets/female.svg";

const Element = ({ username, gender }) => {
  return (
    <ElementWrapper>
      <ProfileImage
        src={gender == "female" ? username ? FemaleSVG : imageFemale : username ? MaleSVG : imageMale}
        alt="Profile Picture"
      />
      <UserNameWrapper>{username}</UserNameWrapper>
    </ElementWrapper>
  );
};

export default Element;

import styled from "styled-components";

export const NavbarWrapper = styled.div`
  height: 75px;
  background-color: #7d7a7a;
  border-bottom: 2px solid #5b3469;
  padding: 16px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoginRegistrationWrapper = styled.div`
  color: white;
  display: flex;
  gap: 7px;
    @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const LoginRegButton = styled.a`
  text-decoration: none;
  color: white;
  cursor: pointer;
`;

export const LngWrapper = styled.div`
  display: flex;
  gap: 12px;
  height: 2rem;
  font-size: 20px;
  > p {
    margin: unset;
  }
  @media (max-width: 1200px) {
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const LngandButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
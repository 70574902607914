import React, { useState } from "react";
import {
  LngWrapper,
  LngandButtonWrapper,
  LoginRegButton,
  LoginRegistrationWrapper,
  NavbarWrapper,
} from "./Navbar.styled";
import { ReactComponent as Icon } from "../assets/logo.svg";
import RegistrationRefModal from "../RegistrationRefModal/RegistrationRefModal";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userExist = localStorage.getItem("token");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const iconStyle = {
    cursor: "pointer",
  };

  const onLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <NavbarWrapper>
      <Icon style={iconStyle} onClick={() => navigate("/my-profile")} />
      <LngandButtonWrapper>
        <LngWrapper>
          <p style={{ cursor: 'pointer' }} onClick={() => onLanguageChange("am")}>🇦🇲</p>
          <p style={{ cursor: 'pointer' }} onClick={() => onLanguageChange("en")}>🇺🇸</p>
          <p style={{ cursor: 'pointer' }} onClick={() => onLanguageChange("ru")}>🇷🇺</p>
        </LngWrapper>
        {!userExist ? (
          <LoginRegistrationWrapper>
            <LoginRegButton
              onClick={() => {
                navigate("/login");
              }}
            >
              {t("login")}
            </LoginRegButton>
            <span>|</span>
            <LoginRegButton onClick={openModal}>{t('registration')}</LoginRegButton>
          </LoginRegistrationWrapper>
        ) : (
          <LoginRegButton
            onClick={() => {
              localStorage.removeItem('token');
              navigate("/login");
            }}
          >
            {t("logout")}
          </LoginRegButton>
        )}
      </LngandButtonWrapper>

      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <RegistrationRefModal closeModal={closeModal} />
      </Modal>
    </NavbarWrapper>
  );
};

export default Navbar;

import React, { useState } from "react";
import {
  Body,
  CloseButton,
  ErrorWrapper,
  FooterCloseButton,
  FooterWrapper,
  Header,
  InputReferralName,
  RegistrationWrapper,
  SearchButton,
} from "./RegistrationRefModal.styled";
import api from "../helpers/api";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const RegistrationRefModal = ({ closeModal }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const searchForUser = async () => {
    setError(false);
    try {

      const { data } = await api("post", `user/signup`, { referral: inputValue });
      if (data == "approved") {
        localStorage.setItem("referralName", inputValue);
        navigate(`/registration`);
      } else {
        throw Error("!2")
      }

      closeModal();
    } catch (e) {
      setError(true);
    }
  };

  return (
    <RegistrationWrapper>
      <Header>
        Գրանցում
        <CloseButton onClick={closeModal}>x</CloseButton>
      </Header>
      <Body>
        <div>{t('referralUsername')}</div>
        <InputReferralName
          type="text"
          id="referalUsername"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t('enterRefUsername')}
        />
        <div style={{ lineHeight: 1.5 }}>
          {t('yourRefIdText')}
        </div>
        {error && (
          <ErrorWrapper>
            {t('refUserNameInValid')}
          </ErrorWrapper>
        )}
      </Body>
      <FooterWrapper>
        <FooterCloseButton onClick={closeModal}>{t('close')}</FooterCloseButton>
        <SearchButton onClick={searchForUser}>{t('search')}</SearchButton>
      </FooterWrapper>
    </RegistrationWrapper>
  );
};

export default RegistrationRefModal;

import styled from "styled-components";

export const MyProfileWrapper = styled.div`
  width: 600px;
  background-color: #d6d5d5;
  margin: auto;
  z-index: 1;

  @media only screen and (max-width: 680px) {
    width: 80%;
    padding-bottom: 23px;
  }
`;

export const InformationWrapper = styled.div`
  margin-left: 162px;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 680px) {
    width: 90%;
    margin: 30px auto 0 auto;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  img {
    width: 200px;
    height: auto;
  }
`;

export const SectionNameUsername = styled.span`
  font-weight: bold;
  margin-right: 55px;
`;

export const SectionNameEmail = styled.span`
  font-weight: bold;
  margin-right: 69px;
`;

export const NameWrapper = styled.div`
font-weight: bold;
`;

export const ImageWrapper = styled.div`
  height: 200px;
  svg {
    ${({isFemale}) => isFemale && 'margin-top: 30px'};
  }
`;

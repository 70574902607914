import styled from "styled-components";

export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e7;
`;

export const LinkStyle = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  padding: 5px 14px;
  text-transform: uppercase;
  background-color: ${({selected}) => selected && '#d6d6d6'};

  &:hover {
    background-color: #5b3469;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    color: white;
  }

`;
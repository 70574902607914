import React, { useState, useEffect } from "react";
import {
  BackButton,
  BackButtonContainer,
  FirstLine,
  ForthLine,
  ForthLineTwoElementsWrapper,
  LineForth,
  LineThird,
  NetworkWrapper,
  SecondLine,
  SuperAdminWrapper,
  ThirdLine,
  ThirdLineLineTwoElementsWrapper,
} from "./Network.style.js";
import ProfileNavbar from "../profileNavbar/ProfileNavbar";
import Element from "./Element.jsx";
import api from "../helpers/api.js";
import { VideoBackground } from "../login/Login.styled.js";
import backgroundVideo from "../assets/backgroundClear.mp4"
import { useTranslation } from "react-i18next";

const Network = () => {
  const [userData, setUserData] = useState([]);
  const [userDataIfSuperadmin, setUserDataIfSuperadmin] = useState([]);
  const [backId, setBackId] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const userid = localStorage.getItem("id");
    setBackId([localStorage.getItem("id")]);
    
    (async () => {
      await api(
        "get",
        `referral/${userid}`,
        {},
        {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      ).then(({ data }) => {
        setUserData(data);

        if (data?.includes('superadmin')) {
          setIsSuperAdmin(true);
          const data1 = data.slice(1, -1);
          setUserDataIfSuperadmin(data1);
        }
      });
    })();
  }, []);

  const fetchDataOfreferral = async (id, isBack = false) => {
    if (!id || (id == '401f8d64-9ae7-47f4-b546-4f0623ef21f3' && !isBack)) {
      return;
    }

    if(id == '401f8d64-9ae7-47f4-b546-4f0623ef21f3' && !isBack) {
      setIsSuperAdmin(true);
    }

    if(!isBack){
      setBackId((prevBackId) => [...prevBackId, id]);
    }

    await api(
      "get",
      `referral/referral/${id}`,
      {},
      {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    ).then(({ data }) => {
      setUserData(data);
      if (data?.includes('superadmin')) {
        setIsSuperAdmin(true);
        const data1 = data.slice(1, -1);
        setUserDataIfSuperadmin(data1);
      }
      else {
        setIsSuperAdmin(false);
      }
    });
  };
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const back = () => {
    if(backId.length > 1){
      if(backId[backId.length - 2] == '401f8d64-9ae7-47f4-b546-4f0623ef21f3'){
        setIsSuperAdmin(true);
      }
      fetchDataOfreferral(backId[backId.length - 2], true);
      const newArray = backId.slice(0, -1);
      setBackId(newArray);
    } 
    else {
      fetchDataOfreferral(backId[0]);
    }
  }

  return (
    <>
      <ProfileNavbar />
      <VideoBackground>
      <video autoPlay loop muted playsInline>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <NetworkWrapper>
      <BackButtonContainer>
        <BackButton onClick={back}>{t('back')}</BackButton>
      </BackButtonContainer>
        <FirstLine>
          <Element
            username={userData[0]?.username}
            gender={userData[0]?.gender}
          />
        </FirstLine>
        {!isSuperAdmin ? (
          <>
            <SecondLine>
              <div onClick={() => fetchDataOfreferral(userData[1]?.id)}>
                <LineThird>|</LineThird>
                <Element
                  username={userData[1]?.username}
                  gender={userData[1]?.gender}
                />
              </div>
              <div onClick={() => fetchDataOfreferral(userData[2]?.id)}>
                <LineThird>|</LineThird>
                <Element
                  username={userData[2]?.username}
                  gender={userData[2]?.gender}
                />
              </div>
              <div onClick={() => fetchDataOfreferral(userData[3]?.id)}>
                <LineThird>|</LineThird>
                <Element
                  username={userData[3]?.username}
                  gender={userData[3]?.gender}
                />
              </div>
              <div onClick={() => fetchDataOfreferral(userData[4]?.id)}>
                <LineThird>|</LineThird>
                <Element
                  username={userData[4]?.username}
                  gender={userData[4]?.gender}
                />
              </div>
            </SecondLine>

            <ThirdLine>
              <ThirdLineLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[1]?.referrals[0]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[1]?.referrals[0]?.username}
                    gender={userData[1]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[1]?.referrals[1]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[1]?.referrals[1]?.username}
                    gender={userData[1]?.referrals[1]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[1]?.referrals[2]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[1]?.referrals[2]?.username}
                    gender={userData[1]?.referrals[2]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[1]?.referrals[3]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[1]?.referrals[3]?.username}
                    gender={userData[1]?.referrals[3]?.gender}
                  />
                </div>
              </ThirdLineLineTwoElementsWrapper>
              <ThirdLineLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[2]?.referrals[0]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[2]?.referrals[0]?.username}
                    gender={userData[2]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[2]?.referrals[1]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[2]?.referrals[1]?.username}
                    gender={userData[2]?.referrals[1]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[2]?.referrals[2]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[2]?.referrals[2]?.username}
                    gender={userData[2]?.referrals[2]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[2]?.referrals[3]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[2]?.referrals[3]?.username}
                    gender={userData[2]?.referrals[3]?.gender}
                  />
                </div>
              </ThirdLineLineTwoElementsWrapper>
              <ThirdLineLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[3]?.referrals[0]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[3]?.referrals[0]?.username}
                    gender={userData[3]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[3]?.referrals[1]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[3]?.referrals[1]?.username}
                    gender={userData[3]?.referrals[1]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[3]?.referrals[2]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[3]?.referrals[2]?.username}
                    gender={userData[3]?.referrals[2]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[3]?.referrals[3]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[3]?.referrals[3]?.username}
                    gender={userData[3]?.referrals[3]?.gender}
                  />
                </div>
              </ThirdLineLineTwoElementsWrapper>
              <ThirdLineLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[4]?.referrals[0]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[4]?.referrals[0]?.username}
                    gender={userData[4]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[4]?.referrals[1]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[4]?.referrals[1]?.username}
                    gender={userData[4]?.referrals[1]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[4]?.referrals[2]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[4]?.referrals[2]?.username}
                    gender={userData[4]?.referrals[2]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(userData[4]?.referrals[3]?.id)
                  }
                >
                  <LineThird>|</LineThird>
                  <Element
                    username={userData[4]?.referrals[3]?.username}
                    gender={userData[4]?.referrals[3]?.gender}
                  />
                </div>
              </ThirdLineLineTwoElementsWrapper>
            </ThirdLine>
            {/* <ForthLine>
              <ForthLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[1]?.referrals[0]?.referrals[0]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[1]?.referrals[0]?.referrals[0]?.username}
                    gender={userData[1]?.referrals[0]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[1]?.referrals[0]?.referrals[1]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    gender={userData[1]?.referrals[0]?.referrals[1]?.gender}
                    username={userData[1]?.referrals[0]?.referrals[1]?.username}
                  />
                </div>
              </ForthLineTwoElementsWrapper>
              <ForthLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[1]?.referrals[1]?.referrals[0]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[1]?.referrals[1]?.referrals[0]?.username}
                    gender={userData[1]?.referrals[1]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[1]?.referrals[1]?.referrals[1]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[1]?.referrals[1]?.referrals[1]?.username}
                    gender={userData[1]?.referrals[1]?.referrals[1]?.gender}
                  />
                </div>
              </ForthLineTwoElementsWrapper>
              <ForthLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[2]?.referrals[0]?.referrals[0]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[2]?.referrals[0]?.referrals[0]?.username}
                    gender={userData[2]?.referrals[0]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[2]?.referrals[0]?.referrals[1]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[2]?.referrals[0]?.referrals[1]?.username}
                    gender={userData[2]?.referrals[0]?.referrals[1]?.gender}
                  />
                </div>
              </ForthLineTwoElementsWrapper>
              <ForthLineTwoElementsWrapper>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[2]?.referrals[1]?.referrals[0]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[2]?.referrals[1]?.referrals[0]?.username}
                    gender={userData[2]?.referrals[1]?.referrals[0]?.gender}
                  />
                </div>
                <div
                  onClick={() =>
                    fetchDataOfreferral(
                      userData[2]?.referrals[1]?.referrals[1]?.id
                    )
                  }
                >
                  <LineForth>|</LineForth>
                  <Element
                    username={userData[2]?.referrals[1]?.referrals[1]?.username}
                    gender={userData[2]?.referrals[1]?.referrals[1]?.gender}

                  />
                </div>
              </ForthLineTwoElementsWrapper>
            </ForthLine> */}
          </>
        ) : (
          <SuperAdminWrapper>
            {userDataIfSuperadmin.map((e) => (
              <div
                onClick={() =>
                  fetchDataOfreferral(
                    e?.id
                  )
                }>
                <Element username={e?.username} gender={e?.gender} />
              </div>
            ))}
          </SuperAdminWrapper>
        )}
      </NetworkWrapper>
      </VideoBackground>
    </>
  );
};

export default Network;

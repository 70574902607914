import styled from "styled-components";

export const RegistrationWrapper = styled.div`
  padding: 40px;
  width: 600px;
  background-color: white;
  margin: 30px auto;
  border: 2px solid #ccc;

  h2 {
    color: #2f313b;
    font-size: 1.25rem;
    font-weight: 500;
  }

  @media only screen and (max-width: 680px) {
    width: 80%;
  }

  @media only screen and (max-width: 420px) {
    padding: 20px;
    width: 87%;
  }
`;

export const RegisterInputName = styled.label`
  color: #7c7c7c;
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: flex;
`;

export const RegisterInput = styled.input`
  font-size: 1rem;
  padding: 8px;
  color: #495057;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  @media only screen and (max-width: 420px) {
    width: 87%;
  }
`;

export const LabelInputWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const RegisterButton = styled.div`

  cursor: pointer;
  background-color: #5b3469;
  border-radius: 0;
  border: none;
  color: #fff;
  font-size: 0.9375rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const ErrorSection = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 2px;
`;

export const EyeIconStyle = styled.span`
  width: 28px;
  cursor: pointer;
`;
export default {
  login: 'Login',
  logout: 'Logout',
  registration: 'Sign Up',
  firstName: 'First Name',
  lastName: 'Last Name',
  userName: 'User Name',
  email: 'E-mail',
  gender: 'Gender',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  male: 'Male',
  female: 'Female',
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  userNameRequired: 'Username is required',
  userNameBusy: 'Username is busy',
  emailRequired: 'E-mail is required',
  emailBusy: 'E-mail is busy',
  changeInformation: 'Change information',
  recoverPassword: 'Recover password',
  wrongEmail: 'Email is incorrect',
  sendPasswordRecCode: 'Send password recovery code',
  enterCode: 'Enter the code',
  verifyCodeInCorrect: 'The verification code is incorrect',
  confirmCode: 'Confirm the code',
  newPassword: 'New password',
  passwordConfirmInvalid: 'Password confirmation is invalid',
  changePassword: 'Change password',
  emailorUserName: 'Email / Username',
  emailUserNameRequired: 'Email / Username is required',
  incorrectData: 'The data entered is incorrect',
  passwordRequired: 'Password is required',
  forgotPassword: 'Forgot password',
  confirmEmail: 'Confirm your Email address',
  sendCodeEmail: 'A confirmation email has been sent to your Email. Please enter received code.',
  wrondCode: 'Wrong code',
  confirmEmailButton: 'Confirm email address',
  back: 'Back',
  createAccount: 'CREATE AN ACCOUNT',
  emailValid: 'Email address must be a valid',
  password6Digits: 'Password must contain at least 6 characters',
  register: 'REGISTER',
  referralUsername: 'Username of your referral',
  yourRefIdText: 'Your referral username is the username of the person who referred you to this site',
  refUserNameInValid: 'Referral Username is not valid. Please check and try again.',
  close: 'Close',
  search: 'Search',
  enterRefUsername: 'Enter Referral Username',
  myProfile: 'MY PROFILE',
  myNetwork: 'MY NETWORK',
}
import React from 'react'
import { FooterWrapper } from './Footer.style'
import { ReactComponent as Icon } from '../assets/logo.svg'

const Footer = () => {
  const iconStyle = {
    width: '150px',
    height: '150px',
  };
  return (
    <FooterWrapper>

      <Icon style={iconStyle} />
    </FooterWrapper>
  )
}

export default Footer
import styled from "styled-components";

export const NetworkWrapper = styled.div`
  background-color: #f2f2f2;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 1;
  height: 237px;
  margin-top: 94px;
  width: 400px;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  border: 2px solid #dcdcdc;
`;

export const FirstLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    span {
      display: flex;
      justify-content: center;
    }
  }
`;
export const SuperAdminWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  justify-content: center;
`;

export const SecondLine = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  gap: 60px;
  align-items: end;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 285px;
    height: 2px;
    background-color: #dcdcdc;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 105.2px;
  span {
    color: #dcdcdc;
    height: 22px;
    font-size: 17px;
  }
`;

export const UserName = styled.span`
  color: black !important;
`;

export const ThirdLine = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  overflow: visible;
`;

export const ForthLine = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
`;

export const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35px;
  cursor:pointer;
`;

export const UserNameWrapper = styled.span`
  font-size: 8px;
`;

export const ForthLineTwoElementsWrapper = styled.div`
  display: flex;
  gap: 10px;

  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
  height: 54px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 45.5px;

    height: 2px;
    background-color: #dcdcdc;
  }
`;

export const LineForth = styled.span`
  font-size: 9px;
  color: #dcdcdc;
`;

export const LineThird = styled.span`
  display: block;
  height: 11px;
  font-size: 9px;
  color: #dcdcdc;
`;

export const ThirdLineLineTwoElementsWrapper = styled.div`
  display: flex;
  gap: 2px;

  margin-top: 20px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 66.7px;

    height: 2px;
    background-color: #dcdcdc;
  }

  div {
    div {
      white-space: nowrap;
      width: 20px;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        font-size: 7px;
      }
    }
  }
`;

export const BackButtonContainer = styled.div`
  transform: translateX(-100px);
`;

export const BackButton = styled.button`
  background-color: #8f4ba8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #814099;
  }
`;


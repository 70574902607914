export default {
  login: 'Вход',
  logout: 'Выход',
  registration: 'Регистрация',
  firstName: 'Имя',
  lastName: 'Фамилия',
  userName: 'Имя Пользователя',
  email: 'Эл. Почта',
  gender: 'Пол',
  password: 'Пароль',
  confirmPassword: 'Подтвердите пароль',
  male: 'Мужской',
  female: 'Женский',
  firstNameRequired: 'Поле имени обязательно',
  lastNameRequired: 'Поле фамилии обязательно',
  userNameRequired: 'Поле имени пользователя обязательно',
  userNameBusy: 'Имя пользователя занято',
  emailRequired: 'Поле эл. почты адреса обязательно',
  emailBusy: 'Адрес эл. почты занят',
  changeInformation: 'Изменить данные',
  recoverPassword: 'Восстановить пароль',
  wrongEmail: 'Эл. почта неверна',
  sendPasswordRecCode: 'Отправить код восстановления пароля',
  enterCode: 'Введите код',
  verifyCodeInCorrect: 'Код подтверждения неправильный',
  confirmCode: 'Подтвердите код',
  newPassword: 'Новый пароль',
  passwordConfirmInvalid: 'Подтверждение пароля неправильно',
  changePassword: 'Изменить пароль',
  emailorUserName: 'Эл. почта / Имя пользователя',
  emailUserNameRequired: 'Поле Эл. почта / Имя пользователя обязательно',
  incorrectData: 'Введенные данные неправильны',
  passwordRequired: 'Необходим пароль',
  forgotPassword: 'Забыли пароль',
  confirmEmail: 'Подтвердите Ваш электронный адрес',
  sendCodeEmail: 'На ваш адрес электронной почты было отправлено письмо с подтверждением. Пожалуйста, введите полученный код.',
  wrondCode: 'Неверный код',
  confirmEmailButton: 'Подтвердите Электронной адрес',
  back: 'Назад',
  myProfile: 'Мой Профиль',
  myNetwork: 'Моя Сеть',
  createAccount: 'СОЗДАТЬ АККАУНТ',
  emailValid: 'Адрес эл. почты должен быть действительным',
  password6Digits: 'Пароль должен содержать не менее 6 символов',
  register: 'ЗАРЕГИСТРИРОВАТЬСЯ',
  referralUsername: 'Имя пользователя вашего реферала',
  yourRefIdText: 'Имя пользователя вашего реферала — это имя пользователя человека, который направил вас на этот сайт.',
  refUserNameInValid: 'Имя пользователя реферала недействительно. Пожалуйста проверьте и попробуйте снова.',
  close: 'Закрыть',
  search: 'Поиск',
  enterRefUsername: 'Введите имя пользователя реферала',
}
import React from "react";
import {
  InformationWrapper,
  MyProfileWrapper,
  NameWrapper,
  SectionNameEmail,
  SectionNameUsername,
  Wrapper,
  ImageWrapper
} from "./MyProfile.styled";
import { useEffect, useState } from "react";
import api from "../helpers/api";
import ProfileNavbar from "../profileNavbar/ProfileNavbar";
import { ReactComponent as MaleSVG } from "../assets/male.svg";
import { ReactComponent as FemaleSVG } from "../assets/female.svg";
import backgroundVideo from "../assets/backgroundClear.mp4"
import { VideoBackground } from "../login/Login.styled";
import { useTranslation } from "react-i18next";
const MyProfile = () => {
  const [user, setUser] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) {
      return;
    }
    (async () => {
      await api(
        "get",
        "user/data",
        {},

        {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      ).then(({ data }) => {
        localStorage.setItem("id", data.id);
        setUser(data);
      });
    })();
  }, []);

  return (
    <>
      <ProfileNavbar />
      <VideoBackground>
      <video autoPlay loop muted playsInline>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <MyProfileWrapper>
        <Wrapper>
          <ImageWrapper isFemale={user?.gender === 'female'}>
            {user?.gender === 'female' ? <FemaleSVG /> : <MaleSVG />}
          </ImageWrapper>
          <NameWrapper>{user.firstName}</NameWrapper>
        </Wrapper>
        <InformationWrapper>
          <div>
            <SectionNameUsername>{t('userName')}:</SectionNameUsername>
            <span>{user.username}</span>
          </div>
          <div>
            <SectionNameEmail>{t('email')}:</SectionNameEmail>
            <span>{user.email}</span>
          </div>
        </InformationWrapper>
      </MyProfileWrapper>
      </VideoBackground>
    </>
  );
};

export default MyProfile;

import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import Registration from "./Registration/Registration";
import MyProfile from "./my-profile/MyProfile";
import Navbar from "./navbar/Navbar";
import Login from "./login/Login";
import Footer from "./footer/Footer";
import Network from "./network/Network";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import ChangeUserData from "./change-user-data/ChangeUserData";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = localStorage.getItem("token");
    if (
      ![
        "/registration",
        "/my-profile",
        "/login",
        "/resetpassword",
        "/network",
        "/forgot-password",
        "/change",
      ].includes(location.pathname)
    ) {
      navigate("/my-profile");
      location.pathname = "/my-profile";
    }
    if (
      !user &&
      (location.pathname === "/my-profile" || location.pathname === "/network")
    ) {
      navigate("/login");
    } else if (
      user &&
      [
        "/login",
        "/registration",
        "/resetpassword",
        "/forgot-password",
      ].includes(location.pathname)
    ) {
      navigate("/my-profile");
    }
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/registration" element={<Registration />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/network" element={<Network />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change" element={<ChangeUserData />} />
        <Route path="/*" element={<MyProfile />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  ErrorWrapper,
  ForgotPassword,
  Header,
  InnerWrapper,
  Input,
  InputLabel,
  LoginButton,
  LoginWrapper,
  RegisterButton,
  VideoBackground,
  Wrapper,
} from "./Login.styled";
import Modal from "../Modal/Modal";
import RegistrationRefModal from "../RegistrationRefModal/RegistrationRefModal";
import api from "../helpers/api";
import { useNavigate } from "react-router-dom";
import backgroundAM from "../assets/backgroundAM.mp4"
import backgroundEN from "../assets/backgroundEN.mp4"
import backgroundRU from "../assets/backgroundRU.mp4"
import { useTranslation } from "react-i18next";

const Login = () => {
  localStorage.removeItem('token');
  const [login, setLogin] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState(backgroundAM);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const submit = async (e) => {
    setLoginError(false);
    setPasswordError(false);

    if (!login) {
      setLoginError(true);
    }
    if (!password) {
      setPasswordError(true);
    }
    if (!login || !password) {
      return;
    }
    if (isEmail(login)) {
      try {
        const { data } = await api("post", "user/login", {
          email: login,
          password,
        }).then((res) => {
          localStorage.setItem("token", res.data);
          navigate("/my-profile");
        });
      } catch {
        setAccountError(true);
      }
    } else {
      try {
        const { data } = await api("post", "user/login", {
          username: login,
          password,
        }).then((res) => {
          localStorage.setItem("token", res.data);
          navigate("/my-profile");
        });
      } catch {
        setAccountError(true);
      }
    }
  };
  const isEmail = (input) => {
    // A simple email regex pattern to check if the input looks like an email
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(input);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(()=> {
    const newLanguage = localStorage.getItem('i18nextLng');
    let newVideoSource;

    if (newLanguage === 'en') {
      newVideoSource = backgroundEN;
    } else if (newLanguage === 'ru') {
      newVideoSource = backgroundRU;
    } else {
      newVideoSource = backgroundAM;
    }

    setVideoSrc(newVideoSource);
  },[localStorage.getItem('i18nextLng')]);

  return (
    <VideoBackground>
      <video key={videoSrc} autoPlay loop muted playsInline>
          <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <LoginWrapper>
        <InnerWrapper>
          <Header>{t('login')}</Header>
          <Wrapper>
            <InputLabel>{t('emailorUserName')}</InputLabel>
            <Input onChange={(e) => setLogin(e.target.value)} />
            {loginError && (
              <ErrorWrapper>
                <strong>{t('emailUserNameRequired')}</strong>
              </ErrorWrapper>
            )}
            {accountError && (
              <ErrorWrapper>
                <strong>{t('incorrectData')}</strong>
              </ErrorWrapper>
            )}
          </Wrapper>
          <Wrapper>
            <InputLabel>{t('password')}</InputLabel>
            <Input onChange={(e) => setPassword(e.target.value)} type="password" />
            {passwordError && (
              <ErrorWrapper>
                <strong>{t('passwordRequired')}</strong>
              </ErrorWrapper>
            )}
          </Wrapper>
          <ForgotPassword
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            {t('forgotPassword')}
          </ForgotPassword>
          <LoginButton onClick={submit}>{t('login')}</LoginButton>
          <RegisterButton onClick={openModal}>{t('registration')}</RegisterButton>
        </InnerWrapper>
        <Modal isOpen={isModalOpen} closeModal={closeModal}>
          <RegistrationRefModal closeModal={closeModal} />
        </Modal>
      </LoginWrapper>
    </VideoBackground>
  );
};

export default Login;

import styled from "styled-components"

export const RegistrationWrapper = styled.div`
  /* width: 500px; */
`;

export const Header = styled.div`
  background-color: #d6d6d6;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
`;

export const CloseButton = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const Body = styled.div`
  padding: 16px;
`;

export const InputReferralName = styled.input`
  background-color: #d6d6d6;
  height: 2rem;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  width: 90%;
  margin: 5px 0;

`;

export const FooterWrapper = styled.div`
  border-top: 1px solid #d6d6d6;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 1rem;

`;

export const FooterCloseButton = styled.div`
  cursor: pointer;
  background-color: #6b6b6b;
  border-radius: 0;
  border: none;
  color: #fff;
  padding: .375rem 0.75rem;
`;

export const SearchButton = styled.div`
  cursor: pointer;
  background-color: #5b3469;
  border-radius: 0;
  border: none;
  color: #fff;
  padding: .375rem 0.75rem;
`;

export const ErrorWrapper = styled.div`
  padding: 16px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
`;

import styled from "styled-components";

export const ForgotPasswordWrapper = styled.div`
    max-width: 560px;
    height: 100%;
    width: 100%;
    padding: 37px 5px 67px;
    margin: 0 auto;
`;

export const Wrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #02C08E;
  backdrop-filter: blur(50px);
  border-radius: 11px;
  padding: 60px 106px 35px;
  min-height: 100%;
`;

export const Header = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #2F313B;
  margin-bottom: 28px;
  text-align: center;
`;

export const EmailLabel = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: #2F313B;
  margin-bottom: 12px;
  display: flex;
`;

export const EmailInput = styled.input`
  border: 1px solid rgba(47, 49, 59, 0.61);
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 33px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const ResetButton = styled.button`
  background: linear-gradient(101.75deg, #794d88 6.03%, #70457f 92.42%), #b548dd;
  box-shadow: 0px 6px 40px #5b3469;
  margin-top: 40px;
  display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    width: 100%;
    min-height: 50px;
    border: none;
    cursor: pointer;
`;

export const ErrorWrapper = styled.span`
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

export const CodeInput = styled.input`
  border: 1px solid rgba(47, 49, 59, 0.61);
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 33px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const Header2 = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #2F313B;
  margin-bottom: 28px;
  text-align: center;
`;

export const CodeConfirmButton = styled.button`
  background: linear-gradient(101.75deg, #794d88 6.03%, #70457f 92.42%), #b548dd;
  box-shadow: 0px 6px 40px #5b3469;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  width: 100%;
  min-height: 50px;
  border: none;
  cursor: pointer;
`;

export const NewPasswordInput = styled.input`
  border: 1px solid rgba(47, 49, 59, 0.61);
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 33px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: 30px;
`;

export const NewPasswordInputConfirm = styled.input`
  border: 1px solid rgba(47, 49, 59, 0.61);
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 33px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const ConfirmNewPasswordButton = styled.button`
  background: linear-gradient(101.75deg, #794d88 6.03%, #70457f 92.42%), #b548dd;
  box-shadow: 0px 6px 40px #5b3469;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  width: 100%;
  min-height: 50px;
  border: none;
  cursor: pointer;
`;
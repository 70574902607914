import React, { useState } from "react";
import {
  CodeConfirmButton,
  CodeInput,
  ConfirmNewPasswordButton,
  EmailInput,
  EmailLabel,
  ErrorWrapper,
  ForgotPasswordWrapper,
  Header,
  Header2,
  NewPasswordInput,
  NewPasswordInputConfirm,
  ResetButton,
  Wrapper,
} from "./ForgotPassword.style";
import api from "../helpers/api";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isCodeInput, setIsCodeInput] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [newPasswordPage, setNewPasswordPage] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [changePasswordError, setChangePasswordError] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const sendCode = async () => {
    setEmailError(false);

    if (!isEmailValid(email)) {
      setEmailError(true);
      return;
    }
    try {
      const { data } = await api("post", "user/loginMail", {
        email,
      });
      setIsCodeInput(true);
    } catch {
      setEmailError(true);
    }
  };

  function isEmailValid(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailPattern.test(email);
  }

  const confirmCode = async () => {
    setCodeError(false);
    try {
      const { data } = await api("post", "user/recoverVerify", {
        email,
        code,
      });
      setNewPasswordPage(true);
    } catch {
      setCodeError(true);
    }
  };

  const submit = async () => {
    if (!newPassword || newPassword !== newPasswordConfirm) {
      setChangePasswordError(true);
      return;
    }
    try {
      await api("post", "user/passwordChange", {
        email,
        newPassword,
      });
      navigate("/login")
    } catch { }
  };

  return (
    <ForgotPasswordWrapper>
      <Wrapper>
        <Header>{t('recoverPassword')}</Header>
        {!newPasswordPage ? (
          !isCodeInput ? (
            <div>
              <EmailLabel>{t('email')}</EmailLabel>
              <EmailInput
                onInput={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {emailError && (
                <ErrorWrapper>
                  <strong>{t('wrongEmail')}</strong>
                </ErrorWrapper>
              )}
              <ResetButton onClick={sendCode}>
                {t('sendPasswordRecCode')}
              </ResetButton>
            </div>
          ) : (
            <>
              <Header2>{t('enterCode')}</Header2>
              <CodeInput
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              {codeError && (
                <ErrorWrapper>
                  <strong>{t('verifyCodeInCorrect')}</strong>
                </ErrorWrapper>
              )}
              <CodeConfirmButton onClick={confirmCode}>
                {t('confirmCode')}
              </CodeConfirmButton>
            </>
          )
        ) : (
          <div>
            <div>
              <EmailLabel>{t('newPassword')}</EmailLabel>
              <NewPasswordInput
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                type="password"
              />
            </div>
            <EmailLabel>{t('confirmPassword')}</EmailLabel>
            <NewPasswordInputConfirm
              onChange={(e) => {
                setNewPasswordConfirm(e.target.value);
              }}
              type="password"
            />

            {changePasswordError && (
              <ErrorWrapper>
                <strong>{t('passwordConfirmInvalid')}</strong>
              </ErrorWrapper>
            )}
            <ConfirmNewPasswordButton onClick={submit}>
              {t('changePassword')}
            </ConfirmNewPasswordButton>
          </div>
        )}
      </Wrapper>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;

import styled from "styled-components";

export const LoginWrapper = styled.div`
  max-width: 550px;
  margin: auto;
  z-index: 1;
`;

export const Header = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #2F313B;
  margin-bottom: 17px;
  text-align: center;
`;

export const InnerWrapper = styled.div`
  padding: 60px 106px 35px;

  @media only screen and (max-width: 480px) {
    padding: 60px 20px 35px;
  }
`;

export const InputLabel = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: #2F313B;
`;

export const Input = styled.input`
  margin-top: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  border: 1px solid rgba(47, 49, 59, 0.61);
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
`;

export const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

export const ForgotPassword = styled.a`
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: #2F313B;
  margin-bottom: 20px;
  padding-bottom: 6px;
  border-bottom: 1px solid #2F313B;
  cursor: pointer;
`;

export const LoginButton = styled.div`
  cursor: pointer;
  background-color: #5b3469;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  width: 100%;
  min-height: 50px;
  border: none;
  background: linear-gradient(101.75deg, #794d88 6.03%, #70457f 92.42%), #b548dd;
  box-shadow: 0px 6px 40px #5b3469;
  box-sizing: border-box;
`;

export const RegisterButton = styled.a`
  background: linear-gradient(100.32deg, #2F313B 2.45%, #545558 100.52%), rgba(47, 49, 59, 0.5);
  box-shadow: 0px 3px 40px rgba(54, 56, 64, 0.6);
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  width: 100%;
  min-height: 50px;
  border: none;
  cursor: pointer;
`;

export const ErrorWrapper = styled.span`
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

export const VideoBackground = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
  video {
    position: absolute;
    width: 463px;
    transform: translateY(-4px);
  }

  height: 72.2vh;
  @media (max-height: 700px) {
    height: 80vh;
  }
`;
export default {
  login: 'Մուտք',
  logout: 'Ելք',
  registration: 'Գրանցում',
  firstName: 'Անուն',
  lastName: 'Ազգանուն',
  userName:'Օգտանուն',
  email: 'էլ․ հասցե',
  gender: 'Սեռ',
  password: 'Գաղտնաբառ',
  confirmPassword:'Հաստատել գաղտնաբառը',
  male: 'Արական',
  female: 'Իգական',
  firstNameRequired: 'Անուն դաշտը պարտադիր է լրացնել',
  lastNameRequired: 'Ազգանուն դաշտը պարտադիր է լրացնել',
  userNameRequired: 'Օգտանուն դաշտը պարտադիր է լրացնել',
  userNameBusy: 'Օգտանունը զբաղված է',
  emailRequired: 'Էլ․ հասցե դաշտը պարտադիր է լրացնել',
  emailBusy: 'Էլ․ հասցեն զբաղված է',
  changeInformation: 'Փոխել տվյալները',
  recoverPassword: 'Վերականգնել գաղտնաբառը',
  wrongEmail: 'էլ. հասցեն սխալ է',
  sendPasswordRecCode: 'Ուղարկել գաղտնաբառի վերականգնման կոդը',
  enterCode: 'Մուտքագրեք կոդը',
  verifyCodeInCorrect: 'Հաստատման կոդը սխալ է',
  confirmCode: 'Հաստատել կոդը',
  newPassword: 'Նոր գաղտնաբառ',
  passwordConfirmInvalid: 'Գաղտնաբառի հաստատումն անվավեր է',
  changePassword: 'Փոխել գաղտնաբառը',
  emailorUserName: 'էլ. հասցե / Օգտանուն',
  emailUserNameRequired: 'էլ. հասցե/Օգտանուն դաշտը պարտադիր է լրացնել',
  incorrectData: 'Մուտքագրված տվյալները սխալ են',
  passwordRequired: 'Գաղտնաբառ դաշտը պարտադիր է լրացնել',
  forgotPassword: 'Մոռացել եմ ծածկագիրը',
  confirmEmail: 'Հաստատեք ձեր Էլ․ Հասցեն',
  sendCodeEmail: 'Ձեր էլ․ հասցեին հաստատման նամակ է ուղարկվել: Խնդրում ենք մուտքագրել ստացված կոդը։',
  wrondCode: 'Սխալ կոդ',
  confirmEmailButton: 'Հաստատել էլ․ հասցեն',
  back: 'Վերադարձ',
  myProfile: 'ԻՄ ԷՋԸ',
  myNetwork: 'ԻՄ ՑԱՆՑԸ',
  createAccount: 'ՍՏԵՂԾԵԼ ՀԱՇԻՎ',
  emailValid: 'Էլ. հասցեն պետք է լինի վավեր էլ. փոստի հասցե',
  password6Digits: 'Գաղտնաբառը պետք է պարունակի նվազագույնը 6 նիշ',
  register: 'ԳՐԱՆՑՎԵԼ',

  referralUsername: 'Ձեր ռեֆերալի Օգտանունը',
  yourRefIdText: 'Ձեր ռեֆերալի Օգտանունը հանդիսանում է Ձեզ այս կայք ուղղորդած անձի մուտքանունը',
  refUserNameInValid: 'Ռեֆերալի Օգտանունը վավեր չէ: Խնդրում ենք ստուգել և փորձել կրկին:',
  close: 'Փակել',
  search: 'Որոնել',
  enterRefUsername: 'Մուտքագրել ռեֆերալի Օգտանունը'
}
import axios from "axios";

const api = async (method, endpoint, data, headers,) => {
  try {
    return await axios({
      method,

      url: "https://harixaqi.am/api/" + endpoint,
      data: { ...data },
      headers,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default api;

import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  line-height: 1.5;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;

  h2 {
    color: #333;
  }
`;


export const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
`;

export const InputField = styled.input`
  font-size: 1rem;
  padding: 8px;
  color: #495057;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const ConfirmButton = styled.div`
  cursor: pointer;
  background-color: #5b3469;
  border-radius: 0;
  border: none;
  color: #fff;
  padding: .375rem 0.75rem;
  width: 55%;
  margin: 20px auto 15px auto;
`;

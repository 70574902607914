import React, { useState } from "react";
import {
  ErrorSection,
  EyeIconStyle,
  LabelInputWrapper,
  RegisterButton,
  RegisterInput,
  RegisterInputName,
  RegistrationWrapper,
} from "./Registration.styled";
import { useForm } from "react-hook-form";
import api from "../helpers/api";
import Modal from "../Modal/Modal.jsx";
import MailConfirmModal from "../MailConfirmModal/MailConfirmModal";
import { ReactComponent as Eye } from "../assets/eye.svg";
import { ReactComponent as ClosedEye } from "../assets/clodesEye.svg";
import { Close } from "../MailConfirmModal/MailConfirmModal.style.js";
import { useTranslation } from "react-i18next";

const Registration = () => {
  const [firstName, setName] = useState("");
  const [lastName, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("male");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [validEmailError, setValidEmailError] = useState(false);
  const [emailIsBusy, setEmailIsBusy] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [validUserNameError, setValidUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordValidError, setPasswordValidError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [eyeIsOpen, setEyeIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const { register } = useForm();

  const submit = async (e) => {
    resetErrors();
    if (!firstName) {
      setNameError(true);
    }
    if (!lastName) {
      setSurnameError(true);
    }
    if (!email) {
      setEmailError(true);
    }
    if (email && !isValidEmail(email)) {
      setValidEmailError(true);
    }
    if (!username) {
      setUserNameError(true);
    }
    if (!password) {
      setPasswordError(true);
    }
    if (password && password.length < 6) {
      setPasswordValidError(true);
    }
    if (password && password !== passwordConfirm) {
      setConfirmPasswordError(true);
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !isValidEmail(email) ||
      !username ||
      !password ||
      (password && password !== passwordConfirm) ||
      (password && password.length < 6)
    ) {
      return;
    }
    const data = {
      firstName,
      lastName,
      email,
      username,
      gender,
      password,
      referral: localStorage.getItem("referralName"),
    };
    try {
      await api("post", "user/signup", data)
      openModal();
    } catch (e) {
      if (e?.response?.data?.message === 'email is busy') {
        setEmailIsBusy(true);
      }
      if (e?.response?.data?.message === 'username is busy') {
        setValidUserNameError(true);
      }
    }
  };

  const resetErrors = () => {
    setNameError(false);
    setSurnameError(false);
    setEmailError(false);
    setValidEmailError(false);
    setUserNameError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setValidUserNameError(false);
    setPasswordValidError(false);
    setEmailIsBusy(false);
  };
  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <RegistrationWrapper>
        <h2>{t('createAccount')}</h2>
        <LabelInputWrapper>
          <RegisterInputName>*{t('firstName')}</RegisterInputName>
          <div>
            <RegisterInput
              {...register("name", { required: true })}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && (
              <ErrorSection>{t('firstNameRequired')}</ErrorSection>
            )}
          </div>
        </LabelInputWrapper>
        <LabelInputWrapper>
          <RegisterInputName>*{t('lastName')}</RegisterInputName>
          <div>
            <RegisterInput
              {...register("surname", { required: true })}
              onChange={(e) => setSurname(e.target.value)}
            />
            {surnameError && (
              <ErrorSection>{t('lastNameRequired')}</ErrorSection>
            )}
          </div>
        </LabelInputWrapper>
        <LabelInputWrapper>
          <RegisterInputName>*{t('email')}</RegisterInputName>
          <div>
            <RegisterInput
              {...register("email", { required: true })}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            {emailError && (
              <ErrorSection>{t('emailRequired')}</ErrorSection>
            )}
            {validEmailError && (
              <ErrorSection>
                {t('emailValid')}
              </ErrorSection>
            )}
            {emailIsBusy && (
              <ErrorSection>{t('emailBusy')}</ErrorSection>
            )}
          </div>
        </LabelInputWrapper>
        <LabelInputWrapper>
          <RegisterInputName>*{t('userName')}</RegisterInputName>
          <div>
            <RegisterInput
              {...register("username", { required: true, maxLength: 16 })}
              onChange={(e) => setUsername(e.target.value)}
            />
            {userNameError && (
              <ErrorSection>{t('userNameRequired')}</ErrorSection>
            )}
            {validUserNameError && (
              <ErrorSection>{t('userNameBusy')}</ErrorSection>
            )}
          </div>
        </LabelInputWrapper>
        <LabelInputWrapper>
          <RegisterInputName>*{t('gender')}</RegisterInputName>
          <input
            type="radio"
            id="male"
            value="male"
            checked={gender === "male"}
            onChange={(e) => {
              setGender(e.target.value);
            }}
          />
          <label htmlFor="male">{t('male')}</label>
          <input
            type="radio"
            id="female"
            value="female"
            checked={gender === "female"}
            onChange={(e) => {
              setGender(e.target.value);
            }}
          />
          <label htmlFor="female">{t('female')}</label>
        </LabelInputWrapper>
        <LabelInputWrapper>

          <RegisterInputName style={{gap: '10px', alignItems: 'center'}}>*{t('password')}
            <EyeIconStyle
              onClick={() => setEyeIsOpen(!eyeIsOpen)}
              style={{ cursor: 'pointer' }}
            >
              {eyeIsOpen ? <Eye /> : <ClosedEye />}
            </EyeIconStyle>
          </RegisterInputName>
          <div>
            <RegisterInput
              {...register("password", { required: true })}
              onChange={(e) => setPassword(e.target.value)}
              type={eyeIsOpen ? 'text' : 'password'}
            />

            {passwordError && (
              <ErrorSection>{t('passwordRequired')}</ErrorSection>
            )}
            {passwordValidError && (
              <ErrorSection>
                {t('password6Digits')}
              </ErrorSection>
            )}
          </div>
        </LabelInputWrapper>
        <LabelInputWrapper>
          <RegisterInputName>*{t('confirmPassword')}</RegisterInputName>
          <div>
            <RegisterInput
              {...register("passwordConfirm", {
                required: true,
                maxLength: 16,
              })}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              type={eyeIsOpen ? 'text' : 'password'}
            />

            {confirmPasswordError && (
              <ErrorSection>{t('passwordConfirmInvalid')}</ErrorSection>
            )}
          </div>
        </LabelInputWrapper>
        <RegisterButton onClick={submit}>{t('register')}</RegisterButton>
      </RegistrationWrapper>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <MailConfirmModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Registration;

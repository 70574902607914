import React from 'react'
import { LinkStyle, NavbarWrapper } from './ProfileNavbar.style'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ProfileNavbar = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <NavbarWrapper>
      <LinkStyle onClick={() => { navigate('/my-profile') }} selected={location.pathname === '/my-profile'}>{t('myProfile')}</LinkStyle>
      <LinkStyle onClick={() => { navigate('/network') }} selected={location.pathname === '/network'}>{t('myNetwork')}</LinkStyle>
      <LinkStyle onClick={() => { navigate('/change') }} selected={location.pathname === '/change'}>{t('changeInformation')}</LinkStyle>
    </NavbarWrapper>
  )
}

export default ProfileNavbar
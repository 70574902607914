import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Close,
  ConfirmButton,
  InputField,
  ModalContent,
  ModalWrapper,
} from "./MailConfirmModal.style";
import api from "../helpers/api";
import { ErrorSection } from "../Registration/Registration.styled";
import { useTranslation } from "react-i18next";

const MailConfirmModal = ({ closeModal }) => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const { t } = useTranslation();
  const submit = async () => {

    try {
      const data = await api("post", "user/verifyRegistration", { code });
      if (data) {
        navigate("/login");
      }
    } catch {
      setCodeError(true);
    }
  };
  return (
    <ModalWrapper>
      <ModalContent>
        <Close onClick={closeModal}>&times;</Close>
        <h2>{t('confirmEmail')}</h2>
        <p>
          {t('sendCodeEmail')}
        </p>
        <InputField
          placeholder={t('enterCode')}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        {codeError &&
        <ErrorSection>{t('wrondCode')}</ErrorSection>
        }
        <ConfirmButton onClick={submit}>{t('confirmEmailButton')}</ConfirmButton>
      </ModalContent>
    </ModalWrapper>
  );
};

export default MailConfirmModal;
